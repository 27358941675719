// import CardValuesHeader from "components/content/card-values-header"
import { cardImage } from "components/survey/utils"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import Product from "models/Product"
import Provider from "models/Provider"
import React, { useState } from "react"
import { JsonLd } from "react-schemaorg"
import { BreadcrumbList } from "schema-dts"
import AppManager from "services/app-manager"
import { SavingsAccountDisclaimer } from "../../../components/content/content-disclaimer"
import { CreditCard as GetStartedButton } from "../../../components/content/get-started-button"
import { ListHeaderProduct } from "components/content/list-header"
import ProductComponent from "../../../components/content/savings-accounts/product-component"
import { SavingsAccount as ProductDetails } from "../../../components/content/product-details"
import Layout from "../../../components/layout"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import * as styles from "./style.module.scss"
import AccountProduct, { rate, rateTier } from "models/product-models/AccountProduct"
import SavingsAccountProvider from "models/provider-models/SavingsAccountProvider"
import ReturnLinkChain from "components/content/return-link-chain"
import { CardAds as Ads, SavingsAccountAds, TermDepositAds } from "components/content/ads"
import InputFilter from "components/content/savings-accounts/input-filter"
import { ExpandMore, ExpandLess } from "@mui/icons-material"
import useMediaQuery from "@mui/material/useMediaQuery"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const BoldText = ({ children }: { children: any }) => {
  return <span className={styles.boldText}>{children}</span>
}

const BulletItem = ({ children, isArticle }: { children: any; isArticle?: boolean }) => {
  if (isArticle) {
    return <Typography.P comp="product-detail">{children}</Typography.P>
  } else {
    return (
      <div style={{ display: "flex", marginLeft: "15px", width: "95.5%" }}>
        <Typography.P comp="list1" style={{ marginRight: 10, marginTop: 5 }}>
          •
        </Typography.P>
        <Typography.P comp="product-detail">{children}</Typography.P>
      </div>
    )
  }
}

function makeProductComponent(product: Product, key: number, isLast?: boolean) {
  return (
    <div key={key} style={{ borderBottom: isLast ? "" : "0.5pt #D2D2D2 solid" }}>
      <ProductComponent product={product as AccountProduct} learnMore />
    </div>
  )
}

function makeReviewComponent(reviews: string[], p3Array: { [id: string]: any }[]): JSX.Element {
  return (
    <div>
      {reviews.map((review, key) => (
        <Typography.P style={{ marginBottom: "15px" }}>
          {review}{" "}
          {key > 1
            ? p3Array.map(p3 => (
                <div>
                  <div style={{ fontWeight: "bold", color: "#E7527A" }}>{p3.offerTitle}</div>
                  {p3.offerDescription}
                  {p3.offerDescription !== "" ? (
                    <span
                      style={{ textDecoration: "none", color: "#4f8ccc", cursor: "pointer" }}
                      onClick={() => {
                        DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_GET_OFFER_SELECTED, { offerId: p3.offer.offerId, providerId: p3.offer.providerId, expertReview: true })
                        AppManager.getInstance().getOffer("Go", p3.offer.offerId, p3.offer, p3.offer.providerId, p3.providerName)
                      }}
                    >
                      here.
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))
            : ""}
        </Typography.P>
      ))}
    </div>
  )
}

const SavingsAccountTemplate = (props: any) => {
  const { providerJson, productId, dateUpdated, ads }: { providerJson: SavingsAccountProvider; productId: string; dateUpdated: string; ads: any } = JSON.parse(props.pageContext.p)
  const provider: Provider = SavingsAccountProvider.Initialise(providerJson)
  const product: AccountProduct = AccountProduct.Initialise(provider.products[productId] as AccountProduct)
  const nameHeading = product.nameLong
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)

  const isWide = useMediaQuery("(min-width:768px)")
  // Expert Review, TODO: refactor after adding in review
  let expertReviewSection: string | JSX.Element = ""

  const appManager = AppManager.getInstance()
  const [depositAmount, setDepositAmount] = useState(appManager.getSavingsDepositAmount())

  const [openBonusCriteria, setOpenBonusCriteria]: [number[], Function] = useState<number[]>([])

  const hasOffer = Object.values(product.offers).some(o => {
    return o.active === "Y" && o.landingPageUrl
  })

  const toggleOpenBonusCriteria = (n: number) => {
    const newOpenBonusCriteria = [...openBonusCriteria]
    if (newOpenBonusCriteria.includes(n)) {
      let i = newOpenBonusCriteria.length - 1
      while (i >= 0) {
        if (newOpenBonusCriteria[i] === n) {
          newOpenBonusCriteria.splice(i, 1)
        }
        i--
      }
    } else {
      newOpenBonusCriteria.push(n)
    }
    setOpenBonusCriteria(newOpenBonusCriteria)
  }

  const ProductInterestRate = () => {
    const rateTiers = Object.values(product.getRatesByDepositTiers())
    rateTiers.sort((a, b) => a.minDeposit - b.minDeposit)
    if (rateTiers.length === 0) {
      if (depositAmount >= product.minBalance && depositAmount <= product.maxBalance) {
        rateTiers.push({
          minDeposit: product.minBalance,
          maxDeposit: product.maxBalance,
          includedRates: [],
        })
      }
    }
    const tierElements: JSX.Element[] = []
    rateTiers.forEach(tier => {
      const standardRateRows: JSX.Element[] = []
      const introRateRows: JSX.Element[] = []
      const bonusRateRows: JSX.Element[] = []
      let totalRate = 0
      let introRates = 0
      let bonusRates = 0
      tier.includedRates.forEach(r => {
        if (r.rateCategory === "BONUS") {
          bonusRates += 1
        } else if (r.rateCategory === "INTRO") {
          introRates += 1
        }
      })
      tier.includedRates.forEach(r => {
        totalRate += r.interestRate
        switch (r.rateCategory) {
          case "STANDARD": {
            const key = standardRateRows.length
            standardRateRows.push(
              <div style={{ display: "flex", borderBottom: "0.5px solid #D2D2D2", paddingTop: "15px", paddingBottom: "15px" }} key={`rate-tier-${tierElements.length + 1}-standard-rate-row-${key}`}>
                <div style={{ width: "67%" }}>
                  <Typography.P comp="product-detail">Base variable interest rate</Typography.P>
                </div>
                <div style={{ width: "33%", textAlign: "right" }}>
                  <Typography.P comp="product-detail-bold">{(r.interestRate * 100).toFixed(2)}%</Typography.P>
                </div>
              </div>,
            )
            break
          }
          case "INTRO": {
            const key = introRateRows.length
            const introPeriod = AccountProduct.getTermInMonths(r.introPeriod || "")
            if (introPeriod > 0) {
              introRateRows.push(
                <div style={{ display: "flex", borderBottom: "0.5px solid #D2D2D2", paddingTop: "15px", paddingBottom: "15px" }} key={`rate-tier-${tierElements.length + 1}-intro-rate-row-${key}`}>
                  <div style={{ width: "75%" }}>
                    <Typography.P comp="product-detail">
                      Bonus fixed interest rate {introRates > 1 && `#${key + 1}`} for{" "}
                      <BoldText>
                        {introPeriod} month{introPeriod !== 1 && "s"}
                      </BoldText>
                    </Typography.P>
                  </div>
                  <div style={{ width: "25%", textAlign: "right" }}>
                    <Typography.P comp="product-detail-bold">{(r.interestRate * 100).toFixed(2)}%</Typography.P>
                  </div>
                </div>,
              )
            }
            break
          }
          case "BONUS": {
            const key = bonusRateRows.length
            const bonusInData = r.minMonthlyDollarDeposit !== null || r.minMonthlyDeposits !== null || r.eomBalanceIncrease !== null || r.maxWithdrawals !== null || r.minEligibleCardTxns !== null
            bonusRateRows.push(
              <div style={{ display: "flex", borderBottom: "0.5px solid #D2D2D2", paddingTop: "15px", paddingBottom: "15px" }} key={`rate-tier-${tierElements.length + 1}-bonus-rate-row-${key}`}>
                <div style={{ display: "block", width: "75%", marginTop: "auto", marginBottom: "auto" }}>
                  <div style={{ display: "flex", cursor: "pointer" }} onClick={() => toggleOpenBonusCriteria(key)}>
                    <Typography.P comp="product-detail">Bonus variable interest rate {bonusRates > 1 && `#${key + 1}`} when conditions are met</Typography.P>
                    {openBonusCriteria.includes(key) ? <ExpandLess style={{ marginTop: "auto", marginBottom: "auto", marginLeft: "8px" }} /> : <ExpandMore style={{ marginTop: "auto", marginBottom: "auto", marginLeft: "8px" }} />}
                  </div>
                  {openBonusCriteria.includes(key) && (
                    <div style={{ marginTop: "20px" }}>
                      {r.minMonthlyDollarDeposit !== null && <BulletItem>deposit at least ${r.minMonthlyDollarDeposit % 1 === 0 ? r.minMonthlyDollarDeposit : r.minMonthlyDollarDeposit.toFixed(2)} each month</BulletItem>}
                      {r.minMonthlyDeposits !== null && (
                        <BulletItem>
                          make at least {r.minMonthlyDeposits} deposit{r.minMonthlyDeposits === 1 ? "" : "s"} each month
                        </BulletItem>
                      )}
                      {r.eomBalanceIncrease !== null && <BulletItem>{r.eomBalanceIncrease <= 1 ? "have a higher account balance at the end of the month" : `increase the account balance by $${r.eomBalanceIncrease % 1 === 0 ? r.eomBalanceIncrease : r.eomBalanceIncrease.toFixed(2)} each month`}</BulletItem>}
                      {r.maxWithdrawals !== null && (
                        <BulletItem>
                          make no {r.maxWithdrawals > 0 && "more than "}withdrawal{r.maxWithdrawals !== 1 && "s"} each month
                        </BulletItem>
                      )}
                      {r.minEligibleCardTxns !== null && (
                        <BulletItem>
                          make at least {r.minEligibleCardTxns} eligible debit card transaction{r.minEligibleCardTxns !== 1 && "s"} each month
                        </BulletItem>
                      )}
                      <BulletItem>{bonusInData && "other "}conditions may apply</BulletItem>
                    </div>
                  )}
                </div>
                <div style={{ width: "25%", textAlign: "right", marginTop: "auto", marginBottom: "auto" }}>
                  <Typography.P comp="product-detail-bold">{(r.interestRate * 100).toFixed(2)}%</Typography.P>
                </div>
              </div>,
            )
            break
          }
        }
      })
      if (standardRateRows.length === 0) {
        // add row for 0% interest if necessary
        standardRateRows.push(
          <div style={{ display: "flex", borderBottom: "0.5px solid #D2D2D2", paddingTop: "15px", paddingBottom: "15px" }} key={`rate-tier-${tierElements.length + 1}-standard-rate-row-0`}>
            <div style={{ width: "75%" }}>
              <Typography.P comp="product-detail">Base variable interest rate</Typography.P>
            </div>
            <div style={{ width: "25%", textAlign: "right" }}>
              <Typography.P comp="product-detail-bold">0.00%</Typography.P>
            </div>
          </div>,
        )
      }
      tierElements.push(
        <div style={{ marginTop: "40px" }} key={`rate-tier-${tierElements.length + 1}`}>
          <div>
            <div>
              <div className={styles.interestRateHeader}>
                <span>
                  ${tier.minDeposit.toLocaleString("en")}
                  {tier.maxDeposit < product.maxBalance ? ` to $${tier.maxDeposit.toLocaleString("en")}` : "+"}
                </span>
                <span>{"(% p.a.)"}</span>
              </div>
              <div className={styles.rateTableHeader}>
                <div style={{ width: "75%" }}>Rate Type</div>
                <div style={{ width: "25%", textAlign: "right" }}>Interest</div>
              </div>
            </div>
            {standardRateRows}
            {introRateRows}
            {bonusRateRows}
            {standardRateRows.length + introRateRows.length + bonusRateRows.length > 1 && (
              <div style={{ display: "flex", borderBottom: "0.5px solid #D2D2D2", paddingTop: "15px", paddingBottom: "15px" }}>
                <div style={{ width: "75%" }}>
                  <Typography.P comp="product-detail">Total variable interest rate including maximum bonus*</Typography.P>
                </div>
                <div style={{ width: "25%", textAlign: "right" }}>
                  <Typography.P comp="product-detail-bold">{(totalRate * 100).toFixed(2)}%</Typography.P>
                </div>
              </div>
            )}
          </div>
        </div>,
      )
    })
    return (
      <div>
        {tierElements.length > 0 ? (
          <div>
            {tierElements}
            <div style={{ textAlign: "left", fontFamily: "arboria-book", color: "#797979", letterSpacing: "0px", fontSize: "16px", paddingTop: "15px" }}>{product.wholeBalanceRateApplication() ? "Once your account reaches a given balance tier, the interest rate for that tier will apply to the whole balance." : "Interest rates for this product only apply to the portion of the account balance that falls within the given balance tier, not the whole account balance."}</div>
          </div>
        ) : (
          <Typography.P>No Rates</Typography.P>
        )}
      </div>
    )
  }

  let breadcrumbName = product.name.replace(provider.name, "") || product.name

  return (
    <Layout title={`${nameHeading} - Review | Stay or Go`} description={product.getMetaDescription()} urlSlug={product.urlSlug ? product.urlSlug : undefined} dateUpdated={dateUpdated} includeMozoDisclaimer>
      <div className="page-container">
        <div className="content-provider-container">
          <ReturnLinkChain
            linkChain={[
              { name: "Savings Accounts", to: "/savings-accounts/" },
              { name: provider.name, to: "/" + provider.urlSlug + "-savings-accounts" },
            ]}
            currentLocationItem={{ name: breadcrumbName }}
            excludeBottomMargin={isWide}
            includeFactCheckedDialog={isWide}
            factCheckedDialogDateUpdated={dateString}
          />
          <div className={styles.heading}>
            <Typography.H1>{nameHeading}</Typography.H1>
          </div>
          {product.forSale === "GRANDFATHERED" ? <div className={styles.subHeading}>(no longer for sale)</div> : null}
          {product.forSale === "PAUSED" ? <div className={styles.subHeading}>(currently not for sale)</div> : null}
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {product.autoDescription}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          <div className="show-for-desktop">
            <ProductComponent product={product} largeCardImage hideName showDisclaimer />
          </div>
          <div className="show-for-mobile">
            <ProductComponent product={product} largeCardImage mobileProduct showDisclaimer />
          </div>
          {/* {product.afFreq === "P1M" || product.rewardsFee !== null || product.directRewardId || product.hasOfferAsterix() ? (
            <Typography.P className={styles.seeDetailsBelow} comp={"flat-18"}>
              * See details below
            </Typography.P>
          ) : null} */}
          {/* {expertReviewSection} */}
          {ads && !hasOffer ? ads.product.id.includes("TD") ? <TermDepositAds ads={ads} /> : ads.product.id.includes("SV") ? <SavingsAccountAds ads={ads} /> : null : null}
          <ListHeaderProduct>Interest Rates</ListHeaderProduct>
          {/* <InputFilter setDepositAmount={setDepositAmount} /> */}
          <ProductInterestRate />
          <ListHeaderProduct>Product Details</ListHeaderProduct>
          {product.description !== null ? (
            <div style={{ marginBottom: "30px", marginTop: "30px" }}>
              <div style={{ color: "#797979", fontFamily: "Arboria-Book, sans-serif", fontSize: "16px", display: "flex" }}>
                <img alt="" src="/images/icon_quote.png" style={{ width: "20px", height: "15.66px", marginRight: "15px" }} />
                <div>{product.description}"</div>
              </div>
              <div style={{ color: "#E7527A", fontFamily: "Arboria-Bold, sans-serif", textAlign: "right", fontSize: "16px" }}>- {provider.name}</div>
            </div>
          ) : null}
          <ProductDetails product={product} provider={provider as SavingsAccountProvider} />
          {/* <div className={styles.cardValuesHeader} style={{ marginTop: "60px", marginBottom: "0px", backgroundColor: "#FBFCFC", paddingTop: "11px", paddingBottom: "27px", height: "60px" }}>
            <CardValuesHeader />
          </div> */}
          {/* {provider.getAlternativeProductList(product, makeProductComponent).length > 0 ? (
            // <div className={styles.listHeader} style={{ marginTop: "0px" }}>
            //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "22px" }}>Alternative Credit Cards</div>
            // </div>
            <ListHeaderProduct style={{ marginTop: "0px" }}>Alternative Credit Cards</ListHeaderProduct>
          ) : null}
          {provider.getAlternativeProductList(product, makeProductComponent)} */}
          {<SavingsAccountDisclaimer isDisclaimerOpen dateString={dateUpdated} wholeBalanceInterest={product.wholeBalanceRateApplication()} />}
        </div>
      </div>
    </Layout>
  )
}

export default SavingsAccountTemplate
