// extracted by mini-css-extract-plugin
export var boldText = "style-module--bold-text--e0d0a";
export var cardImage = "style-module--card-image--b84c4";
export var cardImageImg = "style-module--card-image-img--d4a30";
export var cardRow = "style-module--card-row--2f2c8";
export var cardValuesHeader = "style-module--card-values-header--72e71";
export var description = "style-module--description--02f7f";
export var getStarted = "style-module--get-started--ac112";
export var getStartedButton = "style-module--get-started-button--c5b07";
export var heading = "style-module--heading--bd8a5";
export var interestRateHeader = "style-module--interest-rate-header--bf875";
export var listHeader = "style-module--list-header--82b85";
export var rateTableHeader = "style-module--rate-table-header--70cf0";
export var seeDetailsBelow = "style-module--see-details-below--e7c85";
export var subHeading = "style-module--sub-heading--02c0f";